/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum CategoryTranslationChangeType {
    Name = 1,
    SeoName = 2,
    Url = 3,
    UpperDescription = 4,
    UpperDescriptionAnnotation = 5,
    BottomDescription = 6,
    BottomDescriptionAnnotation = 7,
    SeoKeywords = 8,
    DescriptionTag = 9,
    BrowserTitle = 10,
}
