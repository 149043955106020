/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum WearType {
    All = 0,
    Unused = 1,
    Used = 2,
    NewAndOpenBox = 3,
    NewishAndBazaar = 4,
}
