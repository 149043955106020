/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum CategorySort {
    Default = 0,
    PriceAscending = 1,
    PriceDescending = 2,
    NewestFirst = 5,
    Rating = 6,
    Sales = 7,
    Visitors = 8,
    GbbRating = 9,
    DiscountAbsolute = 10,
    DiscountPercentual = 11,
    SaleStart = 13,
    LocalSales = 16,
    Variety = 18,
    DailySlasherStart = 19,
}
