/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum CategoryState {
    Default = 1,
    Invisible = 2,
    External = 4,
    Substitute = 8,
    Supplementary = 16,
}
