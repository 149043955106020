/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum CategoryFilterOptionsEnum {
    MissingUpperDescription = 0,
    MissingBottomDescription = 1,
    MissingImage = 2,
    OldUrl = 3,
    MissingTopProducers = 4,
    NotFilledInSearchRedirect = 5,
    ContainingUpperDescription = 6,
    MissingParameters = 7,
    MissingParametersInLeftMenu = 8,
    DifferentIdInUrlTranslation = 9,
    NameWithFirstLowerCase = 10,
    MissingButWantedUpperDescription = 11,
    SeoCategoryWithoutGbb = 12,
    NonSeoCategoryWithGbb = 13,
    ParametersRequired = 14,
    AllCategories = 15,
}
