/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum CategoryTextsFields {
    BottomDescription = 1,
    BottomDescriptionAnnotation = 2,
    DescriptionTag = 4,
    Name = 8,
    SeoName = 16,
    Title = 32,
    UpperDescription = 64,
    UpperDescriptionAnnotation = 128,
    CommodityBottomText = 256,
    CommodityFaq = 512,
    CommodityUpperText = 1024,
    Keywords = 2048,
    NavigationText = 4096,
    Url = 8192,
    RedText = 16384,
}
