/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum TargetGroup {
    Always = 0,
    AlzaPremium = 1,
    NotAlzaPremium = 2,
    B2B = 3,
    VIP = 4,
    B2BNominated = 5,
    VIPNominated = 6,
    NotEverAlzaPremium = 7,
}
