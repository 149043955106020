/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum CategoryTranslationType {
    UpperDescription = 1,
    BottomDescription = 2,
    Name = 3,
    SeoName = 4,
    Anything = 5,
}
